<template>
  <b-modal
    ref="reservation-options-modal"
    size="lg"
    :title="
      'Options supplémentaires sélectionnées pour la réservation #' +
      currentReservation.reservation_number
    "
    hide-footer
    @close="$emit('close')"
  >
    <ReservationOptionsList
      v-if="showAdditionalOptions && options && options.length > 0"
      :current-reservation="currentReservation"
      :reservation-options="options"
    />
    <div v-else>
      <p class="my-1">Aucune option supplémentaire disponible.</p>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        @click="$refs['reservation-options-modal'].hide()"
      >
        Fermer
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ReservationOptionsList from "@/views/groups/group/ReservationOptions/ReservationOptionsList.vue";

import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";

export const additionalOptionService = new GroupAdditionalOptionService();

export default {
  name: "ReservationOptionsModal",
  components: {
    BButton,
    BModal,
    ReservationOptionsList,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["currentReservation", "options"],
  data() {
    return {
      reservationOptions: [],
    };
  },
  computed: {
    showAdditionalOptions() {
      return this.$store.state["app-auth"]?.orgSettings
        ?.additional_options_enable;
    },
  },
};
</script>

<style scoped></style>
