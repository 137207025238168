<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <b-skeleton-table
        v-if="loading"
        :rows="5"
        :columns="8"
        :table-props="{ bordered: true, striped: true }"
      >
      </b-skeleton-table>
      <vue-good-table
        v-if="!loading"
        mode="remote"
        :totalRows="totalRecords"
        :columns="columns"
        :rows="reservations"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        @on-cell-click="onCellClick"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          searchFn: onSearch,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped pointer"
      >
        >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            Aucun résultat trouvé
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'reservation_number'"
            class="text-nowrap"
          >
            <b-link
              :to="{
                path: `/groups/${props.row.group_id}/reservation/${props.row.id}`,
              }"
              class="font-weight-bold"
            >
              #{{ props.row.reservation_number }}
            </b-link>
          </span>

          <!-- Column: status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(getStatus(props.row))">
              {{ statusLabel(props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: Group Name -->
          <span v-if="props.column.field === 'group_name'" class="text-nowrap">
            <b-badge variant="light-secondary" pill>
              <b-avatar
                :src="props.row.group_image"
                :variant="avatarColors(flattenUUIDtoBase(props.row.id))"
                icon="people-fill"
                size="md"
                class="mr-1"
              />
              <span class="text-nowrap">{{ props.row.group_name }}</span>
            </b-badge>
          </span>

          <!-- Column: Payment -->
          <span v-else-if="props.column.field === 'payment'">
            {{ getPayment(props.row) }}
          </span>

          <span v-else-if="props.column.field === 'primary_contact'">
            <span class="align-text-top text-capitalize">{{
              getPrimaryContact(props.row)
            }}</span>
          </span>

          <!-- Column: type -->
          <span v-else-if="props.column.field === 'departure_date'">
            <span class="align-text-top text-capitalize">{{
              props.row.departure_date
            }}</span>
          </span>
          <!-- Column: user_id -->
          <span v-else-if="props.column.field === 'user_id'">
            <b-badge variant="light-secondary" pill>
              <b-avatar
                :src="props.row.avatar"
                :variant="avatarColors(flattenUUIDtoBase(props.row.user_id))"
                size="sm"
                class="mr-1"
              />
              <span class="align-text-top text-capitalize">{{
                props.row.agent_name
              }}</span>
            </b-badge>
          </span>

          <span
            v-else-if="props.column.field === 'booking_number'"
            class="align-text-top text-capitalize small"
            v-html="getBookingNumbers(props.row)"
          >
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!--
                <b-dropdown-item @click="changeStatus('SUBMITTED',props.row)"
                                 v-if="props.row.status=='DRAFT'">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Marquer Complétée</span>
              </b-dropdown-item>
                <b-dropdown-item @click="changeStatus('DRAFT',props.row.id)"
                                 v-if="props.row.status=='SUBMITTED'">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Marquer Brouillon</span>
                -->
                <b-dropdown-item
                  :to="{
                    name: 'group-edit-reservation',
                    params: {
                      reservation_id: props.row.id,
                      id: props.row.group_id,
                    },
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeNumberReservation(props.row)">
                  <feather-icon icon="ListIcon" class="mr-50" />
                  <span v-if="props.row.booking_numbers.length > 0"
                    >Modifier les réservations Tour-opérateur</span
                  >
                  <span v-else>Ajouter des réservations Tour-opérateur</span>
                </b-dropdown-item>
                <ReservationNumberModal
                  v-if="currentReservation"
                  ref="res-number-modal"
                  :current-reservation="currentReservation"
                  :main-contact="getPrimaryContact(currentReservation)"
                  @submitReservationNumbers="submitReservationBookingNumbers"
                  @resetReservation="resetReservation"
                />
                <b-dropdown-item
                  @click="noteReservation(props.row)"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                >
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  <span v-if="props.row.notes">Modifier la note</span>
                  <span v-else>Ajouter une nouvelle note</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    tenantConfig.additionalOptionsEnabled &&
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="showReservationOptionsModal(props.row)"
                >
                  <feather-icon icon="ShoppingBagIcon" class="mr-50" />
                  <span> Options supplémentaires </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="
                    deleteReservation(
                      props.row.group_id,
                      props.row.id,
                      props.row.status === 'SUBMITTED'
                    )
                  "
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>
                    {{
                      props.row.status === "CANCELLED" ? "Supprimer" : "Annuler"
                    }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Indiquer 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <ReservationNoteModal
      v-if="currentReservation"
      ref="res-note-modal"
      :current-reservation="currentReservation"
      @submitReservationNote="submitReservationNote"
    />
    <ReservationOptionsModal
      v-if="tenantConfig.additionalOptionsEnabled"
      ref="reservation-options-modal"
      :current-reservation="currentReservation"
      :options="reservationOptions"
      @close="reservationOptions = []"
    />
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BSkeletonTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ref, reactive, onMounted, nextTick } from "@vue/composition-api";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useApplicationContext } from "@/shared/composables/use-application-context";

import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Ripple from "vue-ripple-directive";
import reservationSearch from "@/views/mixin/reservationSearch";

import router from "@/router";
import Roles from "@/modules/authnz/models/role";
import store from "@/store";
import _ from "lodash";

import ReservationOptionsModal from "@/views/groups/group/ReservationOptions/ReservationOptionsModal.vue";
import ReservationNoteModal from "@/views/groups/group/GroupReservations/EditReservation/EditNotesReservation/ReservationNoteModal";
import reservationNote from "@/views/mixin/reservationNote";
import ReservationNumberModal from "@//views/groups/group/GroupReservations/EditReservation/EditNumberReservation/ReservationNumberModal";

import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";

export const groupReservationService = new GroupReservationService();
export const additionalOptionService = new GroupAdditionalOptionService();
export default {
  mixins: [reservationSearch, reservationNote],
  components: {
    BAvatar,
    BSkeletonTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    VueGoodTable,
    BLink,
    ReservationNumberModal,
    ReservationNoteModal,
    ReservationOptionsModal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { getAllAvailableGroups, getOrgReservations } = useGroupApi();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { refOf, store } = useApplicationContext();
    const loading = ref(true);

    const reservations = ref([]);
    const groups = ref([]);
    const currentAuthUser = ref({});
    const currentAgencyId = ref(null);
    const currentReservation = ref(null);
    const users = ref([]);
    const totalRecords = ref(0);
    const serverParams = {
      columnFilters: {},
      sort: {
        field: "",
        type: "",
      },
      searchTerm: "",
      page: 1,
      perPage: 10,
    };
    const reservationOptions = ref([]);

    let tenantConfig = reactive({
      paymentEnabled: false,
      additionalOptionsEnabled: false,
    });

    try {
      tenantConfig.paymentEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
      tenantConfig.additionalOptionsEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.additional_options_enable;
    } catch (e) {
      console.error(e);
    }

    let columns = [
      {
        label: "Numéro de réservation",
        field: "reservation_number",
      },
      {
        label: "Statut",
        field: "status",
      },
      {
        label: "Nom du groupe",
        field: "group_name",
      },
      {
        label: "Contact principal",
        field: "primary_contact",
        sortable: false,
      },
      {
        label: "Date de départ",
        field: "departure_date",
      },
      {
        label: "Réservations Tour-opérateur",
        field: "booking_number",
        sortable: false,
      },
      {
        label: "Conseiller",
        field: "user_id",
        sortable: false,
      },
      {
        label: "Action",
        field: "action",
        sortable: false,
      },
    ];

    if (tenantConfig.paymentEnabled) {
      columns.splice(2, 0, {
        label: "Paiement",
        field: "payment",
      });
    }

    const fetchReservations = async () => {
      try {
        const organizationReservationsResponse = await getOrgReservations(
          serverParams
        );
        reservations.value = organizationReservationsResponse.data;
        totalRecords.value = organizationReservationsResponse.meta.total;
        loading.value = false;
      } catch {
        displayErrorMessage(
          "Une erreur est survenue en essayant de chercher les groupes disponibles"
        );
      }
    };

    const getUsers = async () => {
      let response = await groupReservationService.getAllMembersOfOrg();
      users.value = response.data;
    };

    getUsers();

    onMounted(async () => {
      currentAuthUser.value =
        store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
      currentAgencyId.value = currentAuthUser.value.agency_id;

      fetchReservations();
    });

    const refetchData = () => {
      fetchReservations();
    };

    const changeNumberReservation = (reservation) => {
      currentReservation.value = reservation;
      nextTick(() => {
        refOf("res-number-modal").$children[0].show();
      });
    };

    const showReservationOptionsModal = (reservation) => {
      currentReservation.value = reservation;
      nextTick(() => {
        refOf("reservation-options-modal").$children[0].show();
        fetchReservationOptions(reservation.group_id, reservation.id);
      });
    };

    const fetchReservationOptions = async (groupId, reservationId) => {
      try {
        const { data } = await additionalOptionService.getReservationOptions(
          groupId,
          reservationId
        );
        reservationOptions.value = data;
      } catch (e) {
        displayErrorMessage(
          "Une erreur s'est produite en essayant de retrouver les informations d'options supplémentaires de la réservation."
        );
      }
    };

    const submitReservationBookingNumbers = async (
      reservation,
      bookingNumbers
    ) => {
      try {
        await groupReservationService.updateReservationBookingNumbers(
          reservation.group_id,
          reservation.id,
          bookingNumbers
        );
        displaySuccessMessage("Réservation modifiée");
        refOf("res-number-modal").$refs.modalReservationNumber.hide();
        refetchData();
      } catch (e) {
        displayErrorMessage("La modification a échoué");
      } finally {
        currentReservation.value = null;
      }
    };

    const resetReservation = () => {
      currentReservation.value = null;
      fetchReservations();
    };

    const onCellClick = (params) => {
      if (params.column.field != "action") {
        router.push({
          name: "group-edit-reservation",
          params: { reservation_id: params.row.id, id: params.row.group_id },
        });
      }
    };

    return {
      columns,
      reservations,
      refetchData,
      getUsers,
      users,
      changeNumberReservation,
      currentReservation,
      resetReservation,
      submitReservationBookingNumbers,
      onCellClick,
      loading,
      serverParams,
      totalRecords,
      showReservationOptionsModal,
      tenantConfig,
      reservationOptions,
    };
  },
  data() {
    return {
      Roles,
      currentAuthUser: null,
      dir: false,
      searchTerm: "",
      onSearch: _.debounce(this.applySearch, 300),
    };
  },
  created() {
    this.currentAuthUser =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
  },
  computed: {
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return (character) =>
        Math.round(parseInt(character.slice(-1), UUID_BASE) / BASE);
    },
    avatarColors() {
      const avatarColors = {
        1: "primary",
        2: "dark",
        3: "info",
        4: "success",
        5: "danger",
        6: "warning",
        7: "info",
        8: "secondary",
      };
      return (character) => avatarColors[character];
    },
    statusVariant() {
      const statusColor = {
        DRAFT: "light-warning",
        CANCELLED: "light-danger",
        SUBMITTED: "light-success",
      };
      return (status) => statusColor[status];
    },
    statusLabel() {
      const statusLabel = {
        DRAFT: "Brouillon",
        CANCELLED: "Annulée",
        PAYMENT_IN_PROGRESS: "Paiement en cours",
        SUBMITTED: "Complétée",
      };
      return (status) => statusLabel[status];
    },
    pageLength() {
      return this.serverParams.perPage;
    },
  },
  methods: {
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    },
    stringToHslColor(str, s, l) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      var h = hash % 360;
      return "hsl(" + h + ", " + s + "%, " + l + "%)";
    },
    getStatus(reservation) {
      if (reservation.status) {
        return reservation.status;
      } else {
        return "in_progress";
      }
    },
    getBookingNumbers(reservation) {
      try {
        let bookingNumberString = "";
        if (reservation.booking_numbers.length > 0) {
          reservation.booking_numbers.forEach((bookingNumber) => {
            bookingNumberString +=
              bookingNumber.operator + ": " + bookingNumber.value + "<br>";
          });
        } else {
          bookingNumberString = "À venir";
        }
        return bookingNumberString;
      } catch {
        return "";
      }
    },
    getPayment(reservation) {
      if (reservation.payment) {
        let amount = parseFloat(reservation.payment.amount) / 100;
        return amount.toFixed(2) + " $";
      }
      return "";
    },
    deleteReservation(groupId, reservationId, showCancellationLabels) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: showCancellationLabels
          ? "Procéder à l'annulation"
          : "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          groupReservationService
            .deleteReservation(groupId, reservationId)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: showCancellationLabels ? "Annulé!" : "Supprimé!",
                text:
                  "Votre réservation a été " +
                  (showCancellationLabels ? "annulée." : "supprimée."),
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.refetchData();
            });
        }
      });
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.refetchData();
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.refetchData();
    },
    onSortChange(params) {
      this.serverParams.sort.field = params[0].field;
      this.serverParams.sort.type = params[0].type;
      this.refetchData();
    },
    applySearch(params) {
      this.serverParams.searchTerm = params.searchTerm || "";
      if (params.searchTerm != this.$route.query.search) {
        this.$router.push({ query: { search: params.searchTerm } });
      }
      this.refetchData();
    },
  },
  watch: {
    "$route.query.search": {
      handler: function (value) {
        if (value == undefined) {
          value = "";
        }

        if (this.searchTerm != value) {
          this.searchTerm = value == undefined ? "" : value;
          this.applySearch({ searchTerm: value || "" });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.pointer {
  cursor: pointer;
}
</style>
